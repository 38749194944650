<template>
  <div id="app" class="flex min-h-screen flex-col overflow-x-hidden bg-neutral-25">
    <slot></slot>

    <controller-toast />
  </div>
</template>

<script setup lang="ts">
const route = useRoute();
const { t: $t } = useI18n();

useSeoMeta({
  title: () => {
    if (route.meta.title) {
      return `${$t(route.meta.title as string)} » raidboxes®`;
    }
    return 'raidboxes®';
  },
});
</script>
